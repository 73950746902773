var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left" },
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "primary--text",
                          attrs: { icon: "", color: _vm.color }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-export-variant")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.shareOptions, function(option, index) {
                return _c(
                  "v-list-item",
                  { key: index },
                  [
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "share-button",
                          attrs: {
                            text: "",
                            small: "",
                            target: "_blank",
                            href: option.link
                          }
                        },
                        [
                          _c("v-icon", [_vm._v(_vm._s(option.icon))]),
                          _vm._v(" " + _vm._s(option.title) + " ")
                        ],
                        1
                      )
                    ]
                  ],
                  2
                )
              }),
              _c(
                "v-list-item",
                [
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "share-button",
                        attrs: { text: "", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.copyToClipboard()
                          }
                        }
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-content-copy")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DCSLanguageService.translate("CopyLink")
                            ) +
                            " "
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }