



















































import { Component, Vue } from "vue-property-decorator";
import { DCSLanguageService } from "@/services";

const ShareButtonProps = Vue.extend({
  props: {
    shareLink: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    }
  },
});

@Component
export default class ShareButtonComponent extends ShareButtonProps {
  public DCSLanguageService = new DCSLanguageService();

  public linkedInPrefix = 'https://www.linkedin.com/sharing/share-offsite/?url=';
  public twitterPrefix = 'https://twitter.com/intent/tweet?url=';

  public shareOptions = [
    {
      title: 'LinkedIn',
      icon: 'mdi-linkedin',
      link: this.linkedInPrefix + this.shareLink,
    },
    {
      title: 'Twitter',
      icon: 'mdi-twitter',
      link: this.twitterPrefix + encodeURI(this.shareLink),
    },
  ];

  public copyToClipboard(): void {
    navigator.clipboard.writeText(encodeURI(this.shareLink));
  }

}
